<template>
  <div class="error">
    <h1>404</h1>
    <p><strong>Page not found.</strong></p>
    <p>The requested page could not be found.</p>
  </div>
</template>

<style scoped>
.error{
  text-align: center;
}
</style>